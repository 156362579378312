export default {
  "Key": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinese Traditional (HK)"])},
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "new-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新活動"])},
  "website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["網站"])},
  "community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["社群"])},
  "email-marketing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電郵營銷"])},
  "email-preferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電郵偏好設定"])},
  "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["控制台"])},
  "my-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的個人資料"])},
  "account-security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["帳戶安全"])},
  "add-a-new-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增使用者"])},
  "user-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用者個人資料"])},
  "user-groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用者群組"])},
  "user-group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用者群組"])},
  "courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["課程"])},
  "course-prerequisites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["課程參加條件"])},
  "course-templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["課程範本"])},
  "add-a-new-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增範本"])},
  "edit-course-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯課程範本"])},
  "training-events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["培訓活動"])},
  "add-a-new-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增活動"])},
  "training-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["培訓活動"])},
  "user-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用者訂單"])},
  "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登出"])},
  "resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["資源"])},
  "upload-Resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上傳資源"])},
  "resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["資源"])},
  "edit-Resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯資源"])},
  "discounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["優惠"])},
  "add-a-new-Discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增優惠"])},
  "add-brick-club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增 Brick Club"])},
  "edit-Discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯優惠"])},
  "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["優惠"])},
  "certificates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["證書"])},
  "brick-clubs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brick Club"])},
  "view-club-participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看 Brick Club 會員"])},
  "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["摘要"])},
  "course-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["課程描述"])},
  "registered-attendees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已報名出席者"])},
  "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["計費"])},
  "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["個人"])},
  "course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["課程"])},
  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全部"])},
  "cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已取消"])},
  "scheduled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["預定"])},
  "draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["草稿"])},
  "completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已完成"])},
  "confirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已確認"])},
  "published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已發佈"])},
  "no-registrations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無報名"])},
  "minimum-not-met": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未達到最低要求"])},
  "in review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["審核中"])},
  "unpublished": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未發佈"])},
  "event-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活動名稱"])},
  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["動作"])},
  "training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["培訓"])},
  "trainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["培訓人員"])},
  "course-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["課程範本"])},
  "course-level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["課程等級"])},
  "course-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["課程類型"])},
  "delivery-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上課方式"])},
  "delivery-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上課日期"])},
  "available-countries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["適用國家/地區"])},
  "date-and-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日期及時間"])},
  "location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地點"])},
  "training-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["培訓連結"])},
  "event-owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活動擁有者"])},
  "presenters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主持人"])},
  "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收費"])},
  "payment-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款連結"])},
  "payment-gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款網關"])},
  "attendance-limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出席人數上限"])},
  "maximum-attendees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最多參與人數"])},
  "minimum-attendees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最少參與人數"])},
  "waiting-list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["等候名單"])},
  "go-live": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活動發佈日期"])},
  "registration-cut-off-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["報名截止日期"])},
  "start-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開始時間"])},
  "end-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["結束時間"])},
  "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地區"])},
  "organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["團體"])},
  "payment-method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款方式"])},
  "payment-provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款平台"])},
  "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已付款"])},
  "complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完成"])},
  "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看"])},
  "event-submission-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["感謝你提交活動。Play Included 將審核活動並發佈到網站。適用於需要收取授權費的活動：Play Included 現在會向你發出付款發票。收到付款後，課程將於「上線」日期發佈到網站上。適用於無需收取授權費的活動：Play Included 將批准課程，並於指定「上線」日期發佈到網站上。"])},
  "this-event-is": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["這活動是"])},
  "course-feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["課程意見"])},
  "rating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["評分"])},
  "feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["意見"])},
  "welcome-back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["歡迎回來！"])},
  "your-training-events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你的培訓活動"])},
  "submit-event-for-approval": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交活動以供審批"])},
  "edit-training-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯培訓活動"])},
  "save-training-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["儲存培訓活動"])},
  "confirm-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認活動"])},
  "publish-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["立即發佈活動"])},
  "unpublish-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消發佈活動"])},
  "cancel-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消活動"])},
  "change-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更改收費"])},
  "add-a-presenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增主持人"])},
  "add-another-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增另一個日期"])},
  "select-available-countries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選擇適用國家/地區"])},
  "make-training-event-go-live": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["將培訓活動設為已上線"])},
  "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除"])},
  "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除"])},
  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取消"])},
  "save-changes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["儲存變更"])},
  "delete-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除帳戶"])},
  "change-your-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["變更你的密碼"])},
  "add-session-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增活動備註"])},
  "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["儲存"])},
  "add-existing-participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增現有參與者"])},
  "save-participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["儲存參與者"])},
  "edit-brick-club-participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯 Brick Club 參與者"])},
  "delete-brick-club-participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除 Brick Club 參與者"])},
  "add-new-participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增參與者"])},
  "first-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名字"])},
  "last-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓氏"])},
  "email-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play Included 帳戶電郵地址"])},
  "email-address-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如要變更，你必須重新登入"])},
  "your-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你的角色"])},
  "your-roles-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請在新行輸入每項工作"])},
  "bio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["簡介"])},
  "facilitator-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["促進員設定"])},
  "trainer-settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["培訓人員設定"])},
  "register-of-practitioners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["從業者登記"])},
  "facilitator-public-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目前在網站上無法看到此個人資料。如果你希望在網站上列出，請切換此設定為開啟。"])},
  "public-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開啟時，你的個人資料將顯示在"])},
  "public-message-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["其中包括你的團體、國家、職責及你的會員年齡範圍，以及你的 Brick Club 連結。"])},
  "works-with": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["服務對象"])},
  "trainer-public-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目前在網站上無法看到此個人資料。如果你希望在網站上列出，請切換此設定為開啟。"])},
  "language-and-region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["語言及地區"])},
  "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["城市"])},
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["語言"])},
  "timezone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["時區"])},
  "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代碼"])},
  "promotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["推廣"])},
  "starts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開始"])},
  "expires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["逾期"])},
  "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["國家/地區"])},
  "limit-per-customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每位客戶限額"])},
  "limit-total-uses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用總數限額"])},
  "starts-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開始時間"])},
  "expires-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["逾期時間"])},
  "specific-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["指定使用者"])},
  "specific-organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["指定團體"])},
  "apply-to-courses-tagged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["套用至已標記課程"])},
  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓名"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["日期"])},
  "venue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地點"])},
  "setting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定"])},
  "first-club-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["第一次 Brick Club 活動日期"])},
  "last-club-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上次 Brick Club 活動日期"])},
  "participants-count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["參與人數"])},
  "places-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名額"])},
  "session-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活動備註"])},
  "age-range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年齡範圍"])},
  "session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活動"])},
  "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["附註"])},
  "initials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["縮寫"])},
  "background-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["背景備註"])},
  "brick-club-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brick Club 備註"])},
  "created-at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已創建"])},
  "course-trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在結束時啟動"])},
  "last-modified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上次修改時間"])},
  "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["狀態"])},
  "facilitator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["促進員"])},
  "date-completed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完成日期"])},
  "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用者"])},
  "created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已創建"])},
  "updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已更新"])},
  "training-feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["培訓意見"])},
  "submit-for-review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交以供審核"])},
  "contact-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["聯絡電郵"])},
  "contact-us": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["聯絡我們"])},
  "contact-trainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["聯絡培訓人員"])},
  "save-brick-club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["儲存 Brick Club"])},
  "edit-brick-club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯 Brick Club"])},
  "mobile-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手機號碼"])},
  "for-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["適用於"])},
  "survey-recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["受訪者"])},
  "expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["逾期"])},
  "level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["等級"])},
  "payment-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款類型"])},
  "auto-renew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自動續訂"])},
  "auto-renew-explainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果開啟自動續訂，你的會員訂閱將設自動續訂，並會使用預訂時提信用卡資料完成付款。如果你無意續訂，請關閉此設定。當你的會員資格即將到期，你將收到電郵提醒"])},
  "auto-renew-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*你的信用卡需未過期。"])},
  "child-outcomes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小朋友目標"])},
  "club-trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["每週啟動一次"])},
  "show-parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顯示家長"])},
  "discount-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["優惠代碼"])},
  "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交"])},
  "files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["檔案"])},
  "upload-files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上傳檔案"])},
  "brick-club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brick Club"])},
  "move-this-child-to-new-club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["將此小朋友移至新的 Brick CLub"])},
  "organisations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["團體"])},
  "first-name-initial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名字縮寫"])},
  "family-name-initial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["姓氏縮寫"])},
  "middle-name-initial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["中間名縮寫（選填）"])},
  "parent-carer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["家長/照顧者"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["電郵"])},
  "edit-club-participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯 Brick Club 參與者"])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["歡迎"])},
  "primary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主要"])},
  "additional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["附加"])},
  "medication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["藥物"])},
  "thank-you-parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["感謝"])},
  "brick-club-members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brick Club 會員"])},
  "view-member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看會員"])},
  "Edit-Member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯會員"])},
  "scheduled-events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已預定活動"])},
  "booked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已預訂"])},
  "order-no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂單編號"])},
  "placed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已下達"])},
  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["類型"])},
  "website-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["網站數據"])},
  "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂單"])},
  "my-children": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的小朋友"])},
  "my-child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的小朋友"])},
  "edit-child-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯小朋友資料"])},
  "added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已新增"])},
  "edit-child": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯小朋友"])},
  "capacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["數量"])},
  "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["金額"])},
  "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["數量"])},
  "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用者"])},
  "invoice-issued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已開發票"])},
  "add-dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增日期"])},
  "add-multiple-dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增多個日期"])},
  "capacity-of-club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brick Club 容量"])},
  "add-new-club-member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增 Brick Club 會員"])},
  "add-another": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增另一個"])},
  "save-club-member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["儲存 Brick Club 會員"])},
  "edit club member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯 Brick Club 會員"])},
  "show-members-parent-carer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顯示會員的家長/照顧者"])},
  "brick-club-note-for-member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brick Club 會員須知"])},
  "visible-to-member-parent-carer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["會員的家長/照顧者可見"])},
  "my-brick-clubs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的 Brick Club"])},
  "manage-brick-club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理 Brick Club"])},
  "add-note-for-member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增會員須知"])},
  "visible-to-members-parent-carer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["會員的家長/照顧者可見"])},
  "facilitator-session-feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活動清單及注意事項"])},
  "please-select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請選擇"])},
  "parent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["家長"])},
  "participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["參與者"])},
  "save-survey-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["save-survey-details"])},
  "no-responses-to-this-survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本調查沒有回應"])},
  "view-sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看活動"])},
  "view-progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看進度"])},
  "club-delete-confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["這將刪除此 Brick Club 的所有相關日期、活動備註及學生須知"])},
  "this-club-is-private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本 Brick Club 設為私人"])},
  "this-club-is-public-and-not-accepting-bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果你不想接受預訂申請，請選擇此項"])},
  "this-club-is-public-and-accepting-bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["這將顯示你的電郵及網站"])},
  "please-select-visibility-level": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請選擇可見程度"])},
  "do-not-list-on-directory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不要在 Brick Club 目錄列出"])},
  "list-on-directory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在 Brick Club 目錄列出"])},
  "list-on-directory-as-private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在 Brick Club 目錄上列為私人"])},
  "smartsurvey-feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["session-checklist-survey-alert"])},
  "remove-participant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移除參與者"])},
  "move-club-member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["將會員移至另一個 Brick Club"])},
  "date-added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已新增日期"])},
  "add-a-new-discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增優惠"])},
  "verifone-product-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verifone 產品代碼"])},
  "merchant-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商戶名稱"])},
  "percent-off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["優惠百分比"])},
  "edit-discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯優惠"])},
  "license-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["授權資料"])},
  "issued by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發佈者"])},
  "attendee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出席者"])},
  "event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活動"])},
  "subscription-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂閱資料"])},
  "delegates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["代表"])},
  "invoice-paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已付發票"])},
  "payment-gateway-customer-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款網關客戶 ID"])},
  "payment-gateway-order-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款網關訂單 ID"])},
  "event-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活動日期"])},
  "club-attendance-help-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你可以在此追蹤 Brick Club 會員出席率"])},
  "total-no-of-sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活動總數"])},
  "total-percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["總百分比"])},
  "group-notes-for-session": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活動群組備註"])},
  "session-checklist-scores": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活動清單分數"])},
  "view-childs-progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看小朋友進度"])},
  "secondary-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["備用電郵"])},
  "secondary-phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["備用電話號碼"])},
  "event-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活動 ID"])},
  "get-help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["獲取支援"])},
  "your-trainers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你的培訓人員"])},
  "medication-question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你的小朋友有否服用任何促進員需要知道的藥物？"])},
  "additional-question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["為了你的小朋友在 Brick Club 獲得良好體驗，你的小朋友還有其他事項需要促進員知悉嗎？"])},
  "progress-and-feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["進度及意見"])},
  "member-session-feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["會員活動意見"])},
  "goal-tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目標追蹤"])},
  "goal-tracking-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你可以在此查看並追蹤小朋友在 Brick Club 中的目標。"])},
  "current-goal-tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目前目標追蹤"])},
  "programme-feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["計劃意見"])},
  "member-session-feedback-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你可以在此查看小朋友在 Brick Club 的意見。"])},
  "view-feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看意見"])},
  "view-outcomes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看結果"])},
  "club-members": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brick Club 會員"])},
  "club-members-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你可以在此管理你的 Brick Club 會員並查看他們的個人資料"])},
  "session-checklist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活動清單"])},
  "member-attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["會員出席率"])},
  "member-progress-survey-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是時候評估你的 Brick Club。"])},
  "session-checklist-survey-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活動清單協助你追蹤如何使用 Brick-by-Brick® 方法"])},
  "default-brick-club-survey-alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brick Club 會員對今天的活動有何看法？"])},
  "group-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["群組備註"])},
  "back-to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回"])},
  "member-outcomes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["會員成果"])},
  "email-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此電郵地址將用於入 Play Included 帳戶，並接收來自 Play Included 的電郵"])},
  "show-facilitator-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顯示促進員資料"])},
  "show-trainer-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顯示培訓人員資料"])},
  "your-profile-is-currently-visible-trainer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你的個人資料目前在網站上以培訓人員的身分顯示。"])},
  "your-profile-is-currently-visible-facilitator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你的個人資料目前在網站上以促進員的身分顯示。"])},
  "add-a-member-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增會員備註"])},
  "add-member-session-feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增會員活動意見"])},
  "goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目標"])},
  "add-a-goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增目標"])},
  "date-you-set-this-goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你設定此目標的日期"])},
  "category-of-goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目標類別"])},
  "add-a-member-goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增參與者目標"])},
  "archive-goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["存檔目標"])},
  "completion-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完成日期"])},
  "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["備註"])},
  "achieved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已完成"])},
  "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["是"])},
  "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["否"])},
  "archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["存檔"])},
  "add-progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增進度"])},
  "goal-progress-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["今天，我們會將 Brick Club 會員完成目標的進度評為："])},
  "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["關閉"])},
  "goal-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目標備註"])},
  "group-goal-tracking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["群組目標追蹤"])},
  "add-goal-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你可以在此更新實現目標的進度。"])},
  "are-you-sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你確定嗎？"])},
  "goal-tracking-alert-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請新增最多三個目標，確保目標有意義"])},
  "goal-tracking-helptext-facilitator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你可以在此查看 Brick Club 中每個目標的進度。"])},
  "no-active-goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目前無待完成目標"])},
  "start-score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開始分數"])},
  "end-goal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["結束分數"])},
  "steps-travelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["行走步數"])},
  "sessions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活動"])},
  "no-archived-goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無存檔目標"])},
  "member-session-feedback-helptext-facilitator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你可以在此新增和審閱 Brick Club 會員的活動意見。這將與 Brick Club 會員的家人分享。你可以在此查看和審閱分享權限。"])},
  "arrow-keys-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請使用箭頭查看更多日期"])},
  "date-goal-set": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設定目標日期"])},
  "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["類別"])},
  "child-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小朋友備註"])},
  "child-session-feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小朋友的活動意見"])},
  "progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["進度"])},
  "archived-goals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["存檔目標"])},
  "child-views": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小朋友介面"])},
  "graph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["圖形"])},
  "table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["表格"])},
  "parent-views": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["家長介面"])},
  "facilitator-views": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["促進員介面"])},
  "renewal-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["續訂日期"])},
  "auto-renewal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["自動續訂"])},
  "no-smartsurvey-access-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你目前無權使用此功能。"])},
  "member-notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["會員須知"])},
  "member-views": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["會員介面"])},
  "member-progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["會員進度"])},
  "club-name-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此名稱僅供參考"])},
  "club-address-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果你的 Brick Club 設定為「公開」，這將顯示在 Brick Club 目錄"])},
  "view-brick-club-on-website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在 Brick Club 目錄上查看"])},
  "club-email-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果你已將個人資料及 Brick Club設為可見，則此電郵將顯示在從業者登記及 Brick Club 目錄中"])},
  "host-country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主辦國家/地區"])},
  "what-does-this-mean": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["這代表什麼？"])},
  "end-goal-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最終目標"])},
  "files-download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["檔案（點擊下載）"])},
  "parent-only-field-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["家長/照顧者希望你了解的資訊，協助會員在 Brick Club 中獲得良好體驗。"])},
  "first-delivery-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首個上課日期"])},
  "event-tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["套用至有標籤的活動（可選）"])},
  "usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用"])},
  "brick-club-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brick Club 名稱"])},
  "publish-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發佈日期"])},
  "onboarding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["入職培訓"])},
  "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取"])},
  "professional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["專業人員"])},
  "parent-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果你是希望獲得家長/照顧者資源的家長/照顧者，請選擇此項"])},
  "professional-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果你是專業人員，希望獲得專業資源並可能報讀專業課程，請選擇此項。"])},
  "parent-no-smartsurvey-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此功能尚不適用於你小朋友的 Brick Club。如果此功能可用，你將收到電郵通知"])},
  "facilitator-no-smartsurvey-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此 Beta 版功能目前不適用於你的訂閱類型。如果此功能可用，我們將發送電郵通知你"])},
  "trainer-no-smartsurvey-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此 Beta 版功能目前不適用於你的訂閱類型。如果此功能可用，我們將發送電郵通知你"])},
  "facilitator-evaluation-nudge-banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你覺得 Brick Club 的體驗如何？請填寫 「Brick Club 結束」意見調查"])},
  "complete-survey": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完成調查"])},
  "view-session-attendance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看活動出席率及備註"])},
  "add-presenter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增主持人"])},
  "no-presenters-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沒有主持人"])},
  "brick-club-schedule-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你需要新增 Brick Club 的所有日期，但在初始設定時，你至少必須新增一個日期，其他日期可在設定後透過編輯 Brick Club 新增。"])},
  "no-places-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["名額已滿"])},
  "place-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尚有名額"])},
  "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["角色"])},
  "affiliate-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合作夥伴 ID"])},
  "funds-recieved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["收到資金時間"])},
  "view-all-clubs-length-brick-clubs": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["查看所有 ", _interpolate(_list(0)), " Brick Club"])},
  "view-all-facilitators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看所有促進員"])},
  "facilitators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["促進員"])},
  "please-select-a-brick-club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請選擇 Brick Club"])},
  "no-brick-clubs-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沒有可選 Brick Club"])},
  "attendance-change-warning-message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請注意，在此更改日期亦會更改你 Brick Club 的預定日期。請僅在 Brick Club 活動日期有變動時更改此項"])},
  "record-member-feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["記錄成員活動意見及目標"])},
  "select-country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選取國家/地區"])},
  "save-selected-country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["儲存選取的國家/地區"])},
  "tax-free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["免稅"])},
  "total-meta-pagination-total_entries-items": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["總計 ", _interpolate(_list(0)), " 項目"])},
  "manage-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理個人資料"])},
  "public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["公開"])},
  "private": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私人"])},
  "viewable-on-website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（可在網站查看）"])},
  "not-viewable-on-website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["（無法在網站查看）"])},
  "invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["發票"])},
  "not-paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未付款"])},
  "tax-registration-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["稅務編號"])},
  "purchase-order-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["採購訂單編號"])},
  "not-received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未收到"])},
  "payment-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款金額"])},
  "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["稅項"])},
  "redeemed-license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已兌換授權"])},
  "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["搜尋"])},
  "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活躍"])},
  "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["待定"])},
  "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["逾期"])},
  "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無"])},
  "manual-subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手動訂閱"])},
  "subscribe-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂閱使用者"])},
  "view-all-orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看所有訂單"])},
  "this-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本週"])},
  "this-month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["本月"])},
  "last-week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上週"])},
  "view-all-courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看所有課程"])},
  "add-a-parent-carer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增家長/照顧者"])},
  "upload-resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上傳資源"])},
  "add-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增活動"])},
  "there-was-an-error-fetching-the-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["獲取活動時出錯"])},
  "there-was-an-error-updating-this-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新活動時出錯"])},
  "event-updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已更新活動"])},
  "there-was-an-error-updating-event-visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新活動可見性時出錯"])},
  "event-visibility-updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已更新活動可見性"])},
  "show-on-google-maps": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在 Google 地圖上顯示"])},
  "event-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活動詳情"])},
  "this-will-cancel-the-event-and-notify-all-registered-attendees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["這將取消活動並通知所有已報名的出席者。"])},
  "unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["無限"])},
  "confirm-event-cancellation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["確認取消活動"])},
  "error-updating-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新活動時出錯"])},
  "i-e-paypal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["即 PayPal"])},
  "includes-tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["包括稅項?"])},
  "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["貨幣"])},
  "please-add-a-direct-link-to-help-the-user-find-the-venue-e-g": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請新增直接連結，幫助使用者找到場地，例如"])},
  "google-maps-optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Google 地圖（可選）"])},
  "please-select-a-country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請選擇國家/地區"])},
  "postcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["郵政編碼"])},
  "address-line-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址第 2 行"])},
  "address-line-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地址第 1 行"])},
  "selectedtemplate-type-e-learning-e-learning-online-meeting-link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 連結"])},
  "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["可選"])},
  "event-summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活動摘要"])},
  "you-are-editing-a-event-status-event-any-changes-will-effect-this-event": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["你正在編輯 ", _interpolate(_list(0)), " 活動。任何更改將影響此活動。"])},
  "error-creating-event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["創建活動時出錯"])},
  "event-created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已創建活動"])},
  "please-select-a-country-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請選擇國家/地區"])},
  "submit-training-event-for-review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["提交培訓活動以供審核"])},
  "save-as-draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["另存為草稿"])},
  "selectedtemplate-delivery-e-learning-e-learning-online-meeting-link": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 連結"])},
  "all-times-are-in-short_timezone": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["所有時間均為 ", _interpolate(_list(0))])},
  "for-more-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更多資訊。"])},
  "play-included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play Included"])},
  "there-are-currently-no-templates-available-to-you-please-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目前沒有可用範本，請聯絡"])},
  "use-this-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用此範本"])},
  "to-get-started-please-select-a-course-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要開始使用，請選擇一個課程範本"])},
  "introductory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開場白"])},
  "continuing-professional-development": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["持續專業發展"])},
  "drop-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["臨時會議"])},
  "Training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["培訓"])},
  "add-course-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增課程範本"])},
  "view-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看範本"])},
  "key-programme-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["主要計劃詳情"])},
  "moodle-course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moodle 課程"])},
  "registration-rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["報名規則及方法"])},
  "please-add-atleast-1-rule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請新增至少 1 個報名規則。"])},
  "edit-registration-rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯報名規則"])},
  "pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["定價"])},
  "please-add-atleast-one-country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請新增至少 1 個國家/地區及收費"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["編輯"])},
  "add-a-country-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增國家/地區收費"])},
  "countries-and-pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["國家/地區及定價"])},
  "subscription-level-on-completion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["完成時的訂閱等級"])},
  "merchant-product-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["商戶產品代碼"])},
  "merchant-product-code-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果你輸入 Verifone 產品代碼，則不會套用國家/地區及定價表中的收費，而是使用 2Checkout 收費。"])},
  "merchant-product-code-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["附註：請填寫以下國家/地區，以確保產品能顯示在網站。"])},
  "save-course-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["儲存課程範本"])},
  "select-course-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選擇課程描述"])},
  "view-course-in-cms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在 CMS 中查看課程描述"])},
  "storyblok-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Storyblok 描述"])},
  "template-created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已創建範本"])},
  "error-creating-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["創建範本時出錯"])},
  "template-updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已更新範本"])},
  "error-updating-template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新範本時出錯"])},
  "new-course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新課程"])},
  "country-and-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["國家/地區及定價"])},
  "excluding-vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不含增值稅"])},
  "select-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["全選"])},
  "user-must-be": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用者必須為"])},
  "add-registration-rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增報名規則"])},
  "latest-course-templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最新課程範本"])},
  "see-all-course-templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看所有課程範本"])},
  "upcoming-training-events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["即將舉行的培訓活動"])},
  "see-all-training-events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看所有培訓活動"])},
  "online": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["網上"])},
  "no-date-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沒有可選日期"])},
  "event-invoice-marked-as-issued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["標記為已發出活動發票"])},
  "event-invoice-marked-as-paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["標記為已支付活動發票"])},
  "discount-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["優惠詳情"])},
  "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["非活躍"])},
  "delete-discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除優惠"])},
  "error-deleting-discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["刪除優惠時出錯"])},
  "discount-deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已刪除優惠"])},
  "add-a-discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增優惠"])},
  "discount-used": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0))])},
  "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["標籤"])},
  "enter-new-tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["輸入新標籤，以空格分隔"])},
  "tag-name-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["標籤名稱不能為空"])},
  "tag-created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已創建標籤"])},
  "error-creating-tag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["創建標籤時出錯"])},
  "current-subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目前訂閱"])},
  "subscription-updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已更新訂閱"])},
  "error-updating-subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新訂閲時出錯"])},
  "user-subscribed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已訂閱使用者"])},
  "error-subscribing-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂閱使用者時出錯"])},
  "include-tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inc.增值稅"])},
  "exclude-tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["不含增值稅"])},
  "course-prerequisites-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["課程參加條件"])},
  "to-attend-the-booking-event-name-course-please-accept-our-mandatory-course-prerequisites": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["要參加 ", _interpolate(_list(0)), " 課程，請先接受我們的先修課程。"])},
  "view-club-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看 Brick Club 詳情"])},
  "this-user-has-no-brick-clubs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此使用者尚未經營任何 Brick Club"])},
  "course-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["課程詳情"])},
  "completed-event-date": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["已完成 ", _interpolate(_list(0))])},
  "in-progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["進行中"])},
  "view-certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看證書"])},
  "this-user-has-no-events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此使用者尚未報名任何培訓活動"])},
  "you-have-licenses-available": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["你有 ", _interpolate(_list(0)), " 個可兌換名額及 ", _interpolate(_list(1)), " 個待處理此類活動"])},
  "add-user-to-course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增使用者至課程"])},
  "organisation-has-licenses-available": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["此團體為此類活動提供 ", _interpolate(_list(0)), " 個可兌換名額及 ", _interpolate(_list(1)), " 個待定名額"])},
  "active-courses-by-play-included": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play Included 正在進行課程"])},
  "your-facilitators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你的促進員"])},
  "view-courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看課程"])},
  "courses-cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你知道 Play Included 提供一系列額外的 CPD 課程嗎？"])},
  "your-brick-clubs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你的 Brick Club"])},
  "view-all-clubs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看所有 Brick Club"])},
  "continue-events-cta": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["透過預訂 Brick-by-Brick® 計劃，繼續你的 Brick-by-Brick 之旅：當日促進員課程。"])},
  "update-subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["更新訂閱"])},
  "subscription-expires": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["訂閱逾期 - ", _interpolate(_list(0))])},
  "subscription-expired": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["已逾期訂閲 - ", _interpolate(_list(0))])},
  "current-membership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["目前會員"])},
  "view-all-trainers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看所有培訓人員"])},
  "view-all-users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看所有使用者"])},
  "copy-event-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["複製活動連結"])},
  "trainer-pricing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["培訓人員定價"])},
  "club-note-member-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你可以在此為 Brick Club 活動新增備註。這些備註僅對你可見。你可以在「會員」索引標籤為個別會員新增備註，並選擇與家長/照顧者分享。"])},
  "start-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["開始日期"])},
  "end-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["結束日期"])},
  "export-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["匯出數據"])},
  "create-manual-booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["創建手動預訂"])},
  "notes-only-visible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["備註僅對你可見，亦可設定為對成員的家長或照顧者可見"])},
  "view-resource": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看資源"])},
  "view-resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看資源"])},
  "no-resources": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["目前沒有 ", _interpolate(_list(0)), " 資源"])},
  "role-resources": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize([_interpolate(_list(0)), " 資源"])},
  "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["移動"])},
  "manage-license": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["管理授權"])},
  "credit-debit-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["信用卡/扣賬卡："])},
  "single-booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["單次預訂"])},
  "group-booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["團體預訂"])},
  "answer-choices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["答案選項"])},
  "responses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["回應"])},
  "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["群組"])},
  "subscription-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂閲狀態"])},
  "no-organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沒有團體"])},
  "manual-add-user-to-course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["手動新增使用者至課程"])},
  "user-is-ineligible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用者不符合資格"])},
  "discount-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["優惠金額"])},
  "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["總計"])},
  "select-marketing-preferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選擇營銷偏好"])},
  "marketing-preferences-copy-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["歡迎來到你的 Play Included 網站！我們很高興你加入我們。在探索網站之前，請花一點時間與我們分享你的通訊偏好。我們很樂意為你提供最新精彩消息、貼士及資源。你將自動收到有關你的會員資格或購買事宜的電郵，如要收到其他電郵，請在下方表示同意。"])},
  "i-would-like-to-receive-emails-from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我希望收到來自 Play Included 的電郵，內容涉及："])},
  "marketing-preferences-copy-2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["我們非常重視你的私隱，你的資料將根據 ", _interpolate(_list(0)), " 的規定謹慎處理。你可以隨時瀏覽網站的「我的個人資料」，變更你的偏好設定。"])},
  "privacy-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["私隱政策"])},
  "requires-a-subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["需要訂閱？"])},
  "subscription-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂閲金額"])},
  "tax-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["稅額"])},
  "total-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["總額"])},
  "renewal-only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["僅續訂"])},
  "renewals-only": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["僅續訂"])},
  "affiliate_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["合作夥伴 ID"])},
  "template_field_disabled": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["由於此課程在系統中有 ", _interpolate(_list(0)), " 個活動，因此某些欄位已停用。如果你需要變更任何停用欄位，你需要創建新的範本及活動"])},
  "no-change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["沒有變更"])},
  "payment-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["付款狀態"])},
  "discount-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["優惠 ID"])},
  "discount-percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["優惠百分比"])},
  "discounted-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["優惠金額"])},
  "pretax-amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["稅前金額"])},
  "please-enter-a-payment-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入付款連結"])},
  "please-enter-a-payment-gateway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請輸入付款網關"])},
  "licenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["授權"])},
  "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂閲"])},
  "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂閲"])},
  "One-off fee Product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["單次收費產品"])},
  "Bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["預訂"])},
  "add-new-user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增使用者"])},
  "license-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["授權"])},
  "added-by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增者"])},
  "redeem-place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["兌換地點"])},
  "first-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["首個日期"])},
  "group-booking-financial-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["團體預訂財務資訊"])},
  "places": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["地點"])},
  "net-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["淨價"])},
  "delegate-within-own-organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在我的團體內委派"])},
  "bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["預訂"])},
  "transaction-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["交易 ID"])},
  "order-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂單日期"])},
  "refunded?": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["需要退款嗎？"])},
  "refunded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已退款"])},
  "back-to-website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回我們的網站"])},
  "all-time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有時間"])},
  "sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["銷售"])},
  "abandoned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已放棄"])},
  "select-your-language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選擇你的語言"])},
  "step-of-four": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["第 ", _interpolate(_list(0)), " 步，共 4 步"])},
  "permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["許可"])},
  "parent-onboard-permissions-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play Included 網站是我們管理和監控 Brick Club 的平台。在 Play Included，我們旨在為會員提供優質 Brick Club 體驗，為此我們認為提供方便管理和監控 Brick Club 的工具是關鍵所在。你可以在此閱讀更多關於我們如何管理 Brick Club 會員及 Brick Club 相關的資訊"])},
  "parent-onboarding-attendance-permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我同意允許 Brick Club 促進員使用 Play Included 網站記錄我小朋友的出席率，並為我的小朋友新增活動備註。Brick Club 促進員、你作為會員的家人、任何 Brick Club 管理員及 Play Included 都可以看到這些資料。"])},
  "parent-onboarding-survey-permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我同意允許促進員在 Smart Survey 及 Play Included 網站中記錄我小朋友的進度。此資訊將在 Play Included 網站與你（及任何其他相關的家長/照顧者）分享。數據亦對 Brick Club 管理員及 Play Included 可見。"])},
  "parent-onboarding-no-permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我不同意促進員記錄 Brick Club 的出席或進度。我了解我小朋友的 Brick Club 的出席率或進度將不被記錄。"])},
  "parent-onboarding-aware-of-participation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我的小朋友已被正確告知他們參加了 Brick-by-Brick® 計劃，並已新增至 Play Included 網站。如果你的小朋友不願意參加，請聯絡 Brick Club 促進員。你及你的小朋友可選擇與 Brick Club 促進員、管理員及 Play Included 分享哪些資料，你可以在網站「管理個人資料」下的「我的小朋友」部分管理相關權限。"])},
  "parent-onboarding-aware-check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在此。"])},
  "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["下一個"])},
  "parent-onboarding-started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在開始吧。"])},
  "parent-onboarding-started-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["為確保我們提供最佳的 Brick Club 體驗，我們希望向你確認關於你小朋友的資訊。"])},
  "parent-onboarding-started-text-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["選擇此資訊即表示你同意整個網站將使用你小朋友的姓名縮寫，以便為你及你的小朋友提供更個人化體驗。你可以隨時在網站更改小朋友個人資料中的資訊。"])},
  "parent-onboarding-started-text-3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["你可以閱讀 Play Included 如何管理個人資料 ", _interpolate(_list(0))])},
  "here": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在此。"])},
  "child-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["小朋友資料"])},
  "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["返回"])},
  "parent-carer-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["家長或照顧者資料"])},
  "parent-carer-details-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["接下來，我們將收集你的個人資料。這些資料僅用於就 Brick Club 的事宜及我們系統內你小朋友資料的任何更新與你聯絡。你亦將收到電郵通知。你可以在「我的個人資料」下編輯你的通訊設定。"])},
  "parent-carer-details-text-3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["你可以隨時更新、編輯或修改 ", _interpolate(_named("strong")), " 下方的聯絡資料。"])},
  "parent-carer-details-text-2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["有關 Play Included 如何儲存和管理聯絡資料的資訊 ", _interpolate(_list(0))])},
  "member-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["會員個人資料"])},
  "member-profile-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["我們希望 Brick Clubs 成為會員的有趣、安全的空間。對於我們的促進員而言，在會員來到 Brick Club 之前多掌握一點會員資訊，可以為他們營造融洽的環境，並確保會員的安全與福祉。"])},
  "member-profile-text-2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["此資訊僅與 Brick Club 促進員及相關促進員團體分享，且應僅包含確保 Brick Club 順利安全運作所需的資訊。Play Include 無法提供這些數據，你可以閱讀 Play Include 如何管理數據 ", _interpolate(_list(0))])},
  "medication-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["請勿在此填寫任何身份證明（例如地址、小朋友的全名或出生日期）"])},
  "make-primary-parent-carer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["設為主要照顧者"])},
  "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["重設"])},
  "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["變更你的密碼"])},
  "change-password-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["要編輯你的密碼，你會導向至我們的單一登入 (SSO) 系統。"])},
  "all-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有角色"])},
  "resources-pagination": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["總計 ", _interpolate(_list(0)), " 項目"])},
  "create-brick-club": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增 Brick Club"])},
  "show-all-events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["僅顯示我所在團體的活動"])},
  "show-my-events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["顯示我已預訂代表參加的活動"])},
  "view-my-delegates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看我的代表"])},
  "unauthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["未經授權使用"])},
  "annual-subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["年度訂閱"])},
  "view-subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["查看訂閱"])},
  "background-notes-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此資訊由小朋友的家長/照顧者新增，促進員無法編輯。"])},
  "member-medication-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["會員有否服用任何藥物？"])},
  "parent-carer-consent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["家長/照顧者同意"])},
  "parent-carer-survey-permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["家長/照顧者同意促進員在 Play Included 網站記錄出席率及活動備註"])},
  "parent-carer-progress-permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["家長/照顧者同意促進員在 Play Included 網站記錄進度"])},
  "parent-carer-attendance-notes-permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["家長/照顧者同意促進員在 Play Included 網站記錄出席率及活動備註"])},
  "parent-carer-no-attendance-notes-permission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["家長/照顧者未同意在 Play Included 網站記錄出席率或進度"])},
  "member-background-info-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此資訊由小朋友的家長/照顧者新增，促進員無法編輯。"])},
  "member-medication-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["會員有否服用任何藥物？"])},
  "member-additional-notes-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["家長/照顧者希望你了解的資訊，協助會員在 Brick Club 中獲得良好體驗。"])},
  "parent-carer-subheading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["家長/照顧者同意"])},
  "parent-attendance-session-notes-consent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["家長/照顧者 ", _interpolate(_list(0)), " 同意促進員在 Play Included 網站記錄出席率及活動備註"])},
  "parent-survey-consent": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["家長/照顧者 ", _interpolate(_list(0)), " 同意促進員在 Play Included 網站記錄進度"])},
  "parent-no-consent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["家長/照顧者未同意在 Play Included 網站記錄出席率或進度"])},
  "your-bookings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["你的預訂"])},
  "user_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用者群組"])},
  "training_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["培訓活動"])},
  "training_events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["培訓活動"])},
  "user_groups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["使用者群組"])},
  "add_a_new_event": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增活動"])},
  "brick_clubs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brick Club"])},
  "all-types": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有類型"])},
  "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["活動"])},
  "facilitation-aid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["促進資源"])},
  "club-promotion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["宣傳及營銷材料"])},
  "supporting-asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brick Club 資源"])},
  "training-resources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["培訓資源"])},
  "all-tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有標籤"])},
  "all-languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["所有語言"])},
  "cpd-for-certified-facilitators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["促進員 CPD 課程"])},
  "add_to_moodle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增至 Moodle"])},
  "create-account-in-moodle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["在 Moodle 創建帳戶"])},
  "max-attendees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最多出席者人數"])},
  "attendees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["出席者"])},
  "removed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已移除"])},
  "booking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["預訂"])},
  "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["訂單"])},
  "order-on-website": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["網站訂單"])},
  "access-training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["獲得培訓"])},
  "training-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["培訓狀態"])},
  "certified-facilitator-course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["認證促進者課程"])},
  "advanced-facilitator-course": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["進階促進員課程"])},
  "product-code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["產品代碼"])},
  "add-a-new-organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增團體"])},
  "add-new-organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["新增團體"])},
  "organisation-ran-by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["舉辦者"])},
  "course-blocked-pending-payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["此課程必須付費且已完成先修課程"])},
  "upcoming-courses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["即將開設的課程"])},
  "not-started": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["尚未開始"])},
  "product_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["產品代碼"])},
  "discounted_product_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["優惠產品代碼"])},
  "discount_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["優惠代碼"])},
  "subscriptions_purchased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已購買訂閱"])},
  "turn-off": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已開啟自動續訂，點擊此處關閉"])},
  "turn-on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["已關閉自動續訂，點擊此處開啟"])},
  "directory-website-helptext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["如果你已將個人資料及 Brick Club 設為可見，則此網址將顯示在從業者登記及 Brick Club 目錄中"])}
}